<template>
  <!-- Modal -->

  <div class="modal fade" id="deleteDecreeGosoTemplatesModal" tabindex="-1"
       aria-labelledby="deleteDecreeGosoTemplatesModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удаление приказа</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="deleteDecreeGosoTemplateId">
    Вы точно хотите удалить?
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                  @click="deleteDecreeGosoTemplate">
            Удалить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'DeleteDecreeGosoTemplatesModal',

  computed: {
    ...mapState('decreeGosoTemplates', ['deleteDecreeGosoTemplateId']),
  },
  methods: {
    ...mapActions('decreeGosoTemplates', ['GET_DECREE_GOSO_TEMPLATES_DATA', 'DELETE_DECREE_GOSO_TEMPLATES_DATA']),

    async deleteDecreeGosoTemplate() {
      await this.DELETE_DECREE_GOSO_TEMPLATES_DATA()
      await this.GET_DECREE_GOSO_TEMPLATES_DATA()

    }
  }
}

</script>