<template>
  <div class="container">


    <div class="decree-goso-templates-form mt-4">
      <h4 style="text-align: center">Шаблоны ГОСО</h4>




     <AddDecreeGosoTemplatesModal/>
     <EditDecreeGosoTemplatesModal/>
     <DeleteDecreeGosoTemplatesModal/>



      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#addDecreeGosoTemplatesModal">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить
          </button>
        </div>
      </div>

      <div v-for="(item, index) in decreeGosoTemplates_form.decreeGosoTemplatesInfos" :key="index">
        <div class="card mt-4">
          <div class="card-body">

            <div class="goso-cycle-remove-item__button d-flex flex-row-reverse">
              <a type="button" href="" data-bs-toggle="modal"
              data-bs-target="#deleteDecreeGosoTemplatesModal"
              @click="openRemoveDecreeGosoTemplateModal(item.id)">
                <i class="fas fa-trash-alt">
              </i>
              </a>
            </div>

            <b><p class="card-text">Название приказа:</p></b>
            <p class="card-text">{{item.decree}}</p>
            <b><p class="card-text">Дата приказа:</p></b>
            <p class="card-text">{{convertTimestampToDate(item.date)}}</p>
            <div class="row">
              <div class="col-md-1">
                <router-link :to="'/goso-templates?id=' + item.id">
                  Перейти
                </router-link>
              </div>

              <div class="col-md-1">
                <a type="button" href="" data-bs-toggle="modal"
                   data-bs-target="#editDecreeGosoTemplatesModal"
                   @click="chooseGosoTemplate(item)"
                 >
                  Редактировать
                </a>
              </div>

            </div>


          </div>
        </div>
      </div>


    </div>
  </div>


</template>

<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
import AddDecreeGosoTemplatesModal from '@/components/education-programme/decree-goso-templates/AddDecreeGosoTemplatesModal.vue'
import EditDecreeGosoTemplatesModal from '@/components/education-programme/decree-goso-templates/EditDecreeGosoTemplatesModal.vue'
import DeleteDecreeGosoTemplatesModal from '@/components/education-programme/decree-goso-templates/DeleteDecreeGosoTemplatesModal.vue'
export default {
  name: "DecreeGosoTemplates",
  components: {
    AddDecreeGosoTemplatesModal,
    EditDecreeGosoTemplatesModal,
    DeleteDecreeGosoTemplatesModal
  },


  data() {
    return {
      deleteDecreeGosoTemplatesId: '',
    }
  },
  computed: {
    ...mapState('decreeGosoTemplates', ['decreeGosoTemplates_form']),
  },

  methods: {
    ...mapActions('decreeGosoTemplates', ['POST_DECREE_GOSO_TEMPLATES_DATA', 'PUT_DECREE_GOSO_TEMPLATES_DATA', 'GET_DECREE_GOSO_TEMPLATES_DATA', 'DELETE_EDUCATION_MODULE_DATA']),
    ...mapMutations('decreeGosoTemplates', ['UPDATE_DECREE_GOSO_TEMPLATES_DATA', 'UPDATE_NEW_DECREE_GOSO_TEMPLATES_NAME', 'UPDATE_NEW_DECREE_GOSO_TEMPLATES_DATE',
      'DELETE_ROW_DECREE_GOSO_TEMPLATES_INFO', 'ADD_ROW_DECREE_GOSO_TEMPLATES_INFO', 'CHANGE_EDIT_STATUS_DECREE_GOSO_TEMPLATES_INFO', 'UPDATE_NEW_DECREE_GOSO_TEMPLATES_DATE', 'CHOOSE_DECREE_GOSO_TEMPLATE','CHOOSE_DELETE_DECREE_GOSO_TEMPLATE']),

    convertTimestampToDate,

    async deleteEducationModule(id) {
      await this.DELETE_EDUCATION_MODULE_DATA(id);
      this.$message({title: 'Удаление', text: 'Успешное удаление'})
      await this.GET_DECREE_GOSO_TEMPLATES_DATA();
    },
    async editDecreeGosoTemplates(index) {
      this.CHANGE_EDIT_STATUS_DECREE_GOSO_TEMPLATES_INFO(index)

    },
    async openDeleteDecreeGosoTemplatesModal(id) {
      this.deleteDecreeGosoTemplatesId = id;
      console.log(this.deleteDecreeGosoTemplatesId, "DELETE ID")
    },
    async updateDecreeGosoTemplates(id, index) {
      delete this.decreeGosoTemplates_form.decreeGosoTemplatesInfos[index].edit_status;
      await this.PUT_DECREE_GOSO_TEMPLATES_DATA(id)
    },

    deleteRowDecreeGosoTemplatesInfo(index) {
      this.DELETE_ROW_DECREE_GOSO_TEMPLATES_INFO()
    },
    addRowDecreeGosoTemplatesInfo() {
      this.ADD_ROW_DECREE_GOSO_TEMPLATES_INFO()
    },
    submitDecreeGosoTemplates() {

      this.POST_DECREE_GOSO_TEMPLATES_DATA().then(res => {
        if (res.success == true) {
          this.GET_DECREE_GOSO_TEMPLATES_DATA();
          this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Добавление', text: errorText})
        }
      })
    },
    changeDecreeGosoTemplatesData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_DECREE_GOSO_TEMPLATES_DATA({item, property, value})
    },
    changeNewDecreeGosoTemplatesName(property, e) {
      this.UPDATE_NEW_DECREE_GOSO_TEMPLATES_NAME({value: e.target.value, property})
    },

    changeNewDecreeGosoTemplatesDate(property, e) {
      this.UPDATE_NEW_DECREE_GOSO_TEMPLATES_DATE({value: e.target.value, property})
    },

    chooseGosoTemplate(decree){
      this.CHOOSE_DECREE_GOSO_TEMPLATE(decree)
    },
    openRemoveDecreeGosoTemplateModal(id){
      this.CHOOSE_DELETE_DECREE_GOSO_TEMPLATE(id)
    }
  },
  mounted() {
    this.GET_DECREE_GOSO_TEMPLATES_DATA();
  }
}
</script>

<style scoped>

</style>