<template>
    <!-- Modal -->

    <div class="modal fade" id="editDecreeGosoTemplatesModal" tabindex="-1"
         aria-labelledby="editDecreeGosoTemplatesModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Редактирование приказа</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="Object.keys(decreeGosoTemplate).length">
                        <div class="col-md-12 mt-4">

                            <div class="form-group row mt-4">
                                <label for="decree_goso_templates_name" class="col-md-3 col-form-label">Название
                                    приказа</label>
                                <div class="col-md-9">
                                    <textarea id="decree_goso_templates_name" type="text" class="form-control"
                                              :value="decreeGosoTemplate.decree"
                                              @input="changeDecreeGosoTemplate('decree', $event)">
                                    </textarea>
                                </div>

                            </div>
                            <div class="form-group row mt-4">
                                <label for="decree_goso_templates_date" class="col-md-3 col-form-label">Дата
                                    приказа</label>
                                <div class="col-md-9">
                                    <input id="decree_goso_templates_date" type="date" class="form-control"
                                           :value="convertTimestampToDate(decreeGosoTemplate.date)"
                                           @input="changeDecreeGosoTemplate('date', $event, true)">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            @click="updateDecreeGosoTemplate">
                        Обновить
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
    import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"

    export default {
        name: 'EditDecreeGosoTemplatesModal',
        data() {
            return {
                startDate: '1920-01-01',
            }
        },
        computed: {
            ...mapState('decreeGosoTemplates', ['decreeGosoTemplate']),
        },
        methods: {
            ...mapMutations('decreeGosoTemplates', ['CHANGE_DECREE_GOSO_TEMPLATE']),
            ...mapActions('decreeGosoTemplates', ['PUT_DECREE_GOSO_TEMPLATES_DATA', 'GET_DECREE_GOSO_TEMPLATES_DATA']),
            convertTimestampToDate,
            changeDecreeGosoTemplate(property, e, isDate = false) {
                let value = e.target.value
                if (isDate) {
                    if (new Date(value) < new Date(this.startDate)) {
                        return
                    }
                    value = parseInt((new Date(value)).getTime() / 1000)
                }
                this.CHANGE_DECREE_GOSO_TEMPLATE({property, value})
            },
            async updateDecreeGosoTemplate() {
                await this.PUT_DECREE_GOSO_TEMPLATES_DATA()
                await this.GET_DECREE_GOSO_TEMPLATES_DATA()
            }
        }
    }

</script>