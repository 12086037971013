<template>
  <!-- Add Decree Goso Templates Modal -->
  <div class="modal fade" id="addDecreeGosoTemplatesModal" tabindex="-1"
       aria-labelledby="addDecreeGosoTemplatesModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addDecreeGosoTemplatesModalLabel">Добавление шаблона</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mt-4">

            <div class="form-group row mt-4">
              <label for="decree_goso_templates_name" class="col-md-3 col-form-label">Название приказа</label>
              <div class="col-md-9">
            <textarea id="decree_goso_templates_name" type="text" class="form-control"
                      @input="changeNewDecreeGosoTemplatesName('decree', $event)">
            </textarea>
              </div>

            </div>
            <div class="form-group row mt-4">
              <label for="decree_goso_templates_date" class="col-md-3 col-form-label">Дата приказа</label>
              <div class="col-md-9">
                <input id="decree_goso_templates_date" type="date" class="form-control"
                       @input="changeNewDecreeGosoTemplatesDate('date', $event)">
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    @click="submitDecreeGosoTemplates()">
              Добавить
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Отменить
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>
<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "AddDecreeGosoTemplatesModal",


  data() {
    return {
    }
  },
  computed: {
    ...mapState('decreeGosoTemplates', ['decreeGosoTemplates_form']),
  },

  methods: {
    ...mapActions('decreeGosoTemplates', ['POST_DECREE_GOSO_TEMPLATES_DATA', 'GET_DECREE_GOSO_TEMPLATES_DATA']),
    ...mapMutations('decreeGosoTemplates', ['UPDATE_NEW_DECREE_GOSO_TEMPLATES_NAME', 'UPDATE_NEW_DECREE_GOSO_TEMPLATES_DATE']),

    submitDecreeGosoTemplates() {

      this.POST_DECREE_GOSO_TEMPLATES_DATA().then(res => {
        if (res.success == true) {
          this.GET_DECREE_GOSO_TEMPLATES_DATA();
          this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Добавление', text: errorText})
        }
      })
    },

    changeNewDecreeGosoTemplatesName(property, e) {
      this.UPDATE_NEW_DECREE_GOSO_TEMPLATES_NAME({value: e.target.value, property})
    },

    changeNewDecreeGosoTemplatesDate(property, e) {
      this.UPDATE_NEW_DECREE_GOSO_TEMPLATES_DATE({value: e.target.value, property})
    }
  },
  mounted() {
    this.GET_DECREE_GOSO_TEMPLATES_DATA();
  }
}
</script>